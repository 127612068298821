import React,{ useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth, useSigninCheck } from "reactfire";
import { sendPasswordResetEmail } from "firebase/auth";
import { toast, ToastContainer } from "react-toastify";

const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const { status, data: signInCheckResult } = useSigninCheck();

  const [state, setState] = useState({
    email: "",
    errorTxt: "",
  });

  const { email, errorTxt } = state;

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const btnPasswordResetClick = () => {
    // login
    // signInWithEmailAndPassword(auth, email, password)
    //   .then((userCred) => {
    //     console.log(userCred.user);
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //     setState({ ...state, errorTxt: "Please provide correct info." });
    //   });
    if (email != "") {
      sendPasswordResetEmail(auth, email)
        .then((_) => {
          toast.success("Password Reset Email sent.");
        })
        .catch((_) => {
          toast.error("Something went wrong");
          setState({ ...state, errorTxt: "Something went wrong" });
        });
    }
  };

  if (status === "loading") {
    return <span>loading...</span>;
  }

  if (signInCheckResult.signedIn === true) {
    navigate("/dashboard");
  }

  return (
    <div className="flex flex-col sm:flex-row">
      <div className="flex-1 p-6 ">
        <ToastContainer />
        <div className="hero container max-w-screen-lg mx-auto pb-10 flex flex-col">
          <img src="/assets/logo.svg" className="h-16 mx-auto" />
          <div className="text-4xl my-6 text-center">Forgot Password?</div>

          {/* form */}
          <div className="container w-3/4 self-center">
            <div>EMAIL</div>
            <input
              type="email"
              placeholder="Your Email here..."
              className="mt-2 block w-full px-3 py-2 bg-gray-100 rounded  placeholder-gray-400
      focus:outline-none "
              value={email}
              name="email"
              onChange={handleChange}
            />

            {errorTxt !== "" ? (
              <div className="mt-2 text-red-500">{errorTxt}</div>
            ) : (
              <></>
            )}

            <button
              className="block w-full mt-6 rounded px-4 py-2 text-white bg-orange-500 hover:bg-orange-400 active:bg-orange-600 focus:outline-none focus:ring focus:ring-orange-300"
              onClick={btnPasswordResetClick}
            >
              Send Password Reset Link
            </button>

            <div className="flex flex-row justify-between mt-6">
              <div className="text-gray-500">Goto </div>
              <Link className=" text-blue-600 hover:text-blue-900" to="/login">
                Login
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-1 p-6 h-screen bg-gradient-to-br from-yellow-400 to-pink-600">
        <div className="text-4xl text-white font-bold">Contact Support</div>
        <div className="text-white mt-4">
          We provide Customization, UI &amp; UX Design Service, App Development
          Service.
        </div>
        <div className="mt-6 flex flex-col w-1/2">
          <a
            className=" w-auto cursor-pointer text-white"
            href="https://codecanyon.net/user/cqdev"
          >
          </a>
          <a
            className=" w-auto cursor-pointer text-white"
            href="mailto:support@coderqueenhelp.freshdesk.com"
          >
          </a>
          <a
            className=" w-auto cursor-pointer text-white"
            href="https://wa.me/919499888170"
          >
          </a>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
