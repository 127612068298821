import React from "react";
import { Link } from "react-router-dom";

export const Button = ({ child, onClick }) => {
  return (
    <button
      onClick={onClick}
      className="bg-violet-600 hover:bg-violet-700 py-2 px-4 rounded text-white"
    >
      {child}
    </button>
  );
};
export const InfoButton = ({ child, onClick }) => {
  return (
    <button
      onClick={onClick}
      className="bg-gray-200 hover:bg-gray-300 py-2 px-4 rounded text-gray-600"
    >
      {child}
    </button>
  );
};

export const DangerButton = ({ child, onClick }) => {
  return (
    <button
      onClick={onClick}
      className="bg-red-600 hover:bg-red-700 py-2 px-4 rounded text-white"
    >
      {child}
    </button>
  );
};
export const SuccessButton = ({ child, onClick }) => {
  return (
    <button
      onClick={onClick}
      className="bg-green-600 hover:bg-green-700 py-2 px-4 rounded text-white"
    >
      {child}
    </button>
  );
};
