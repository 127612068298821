import React from "react";
import { Icon } from "@iconify/react";
import postOfficeIcon from "@iconify-icons/mdi/local-post-office";
import { Link } from "react-router-dom";
import Header from "../../components/header";
const EmailVerificationPage = () => {
  return (
    <div>
      <Header />
      <div className="flex h-screen text-center flex-col items-center justify-center">
        <Icon
          icon={postOfficeIcon}
          height="200"
          width="200"
          className="text-green-500 animate-bounce inline-block"
        />
        <div className="mt-2 text-2xl italic font-light">
          Verification link sent to your E-Mail. verify and then proceed for
          login.
        </div>

        <Link
          to="/"
          className="mt-4 rounded bg-yellow-400 hover:bg-yellow-500 py-2 px-4"
        >
          Goto Login
        </Link>
      </div>
    </div>
  );
};

export default EmailVerificationPage;
