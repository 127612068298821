import { Icon } from "@iconify/react";
import React from "react";
import dashboardIcon from "@iconify-icons/mdi/home-outline";
import libraryIcon from "@iconify-icons/mdi/library";
import userIcon from "@iconify-icons/mdi/people-outline";
import editorsIcon from "@iconify-icons/mdi/person-multiple-add-outline";
import settingsIcon from "@iconify-icons/mdi/settings-outline";
import notifcationIcon from "@iconify-icons/mdi/bell-notification-outline";
import categoryIcon from "@iconify-icons/mdi/category-outline";
import subsriptionIcon from "@iconify-icons/mdi/payment";
import messageIcon from "@iconify-icons/mdi/phone-outgoing-outline";

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <Icon className="inline-block" icon={name} width={22} height={22} />
);

export const sidebarPaths = [
  {
    title: "Home",
    path: "/dashboard",
    icon: getIcon(dashboardIcon),
  },
  {
    title: "Uploads",
    path: "/dashboard/manageuploads",
    icon: getIcon(libraryIcon),
  },
  {
    title: "Categories",
    path: "/dashboard/categories",
    icon: getIcon(categoryIcon),
  },
   {
     title: "Users",
     path: "/dashboard/users",
     icon: getIcon(userIcon),
   },
  {
    title: "Editors",
    path: "/dashboard/editors",
    icon: getIcon(editorsIcon),
  },
   {
     title: "Notification",
     path: "/dashboard/notification",
     icon: getIcon(notifcationIcon),
   },
   {
     title: "Subscription",
     path: "/dashboard/subscription",
     icon: getIcon(subsriptionIcon),
   },
   {
     title: "Settings",
     path: "/dashboard/settings",
     icon: getIcon(settingsIcon),
   },
//  {
//    title: "Contact",
//    path: "/dashboard/support",
//    icon: getIcon(messageIcon),
//  },
];
