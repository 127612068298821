import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelopeOpen,
  faCrown,
  faChessKnight,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import { InfoButton } from "../../../components/button";
import React from "react";

export const UserCard = ({ user, editAction }) => {
  return (
    <div className=" bg-white shadow-md  rounded-2xl px-8 py-6 mb-4">
      <div className="text-xl font-bold">{user.name}</div>
      <div className="mt-2">
        <FontAwesomeIcon icon={faEnvelopeOpen} /> &nbsp;{user.email}
      </div>
      <div
        className={
          user.role === "admin" ? "mt-2 text-green-500" : "mt-2 text-amber-500"
        }
      >
        <FontAwesomeIcon
          icon={user.role === "admin" ? faCrown : faChessKnight}
        />
        &nbsp; Role: {user.role}
      </div>
      <div className="mt-2">
        <InfoButton
          onClick={editAction}
          child={
            <span>
              <FontAwesomeIcon icon={faPen} /> Edit
            </span>
          }
        />
      </div>
    </div>
  );
};
