import { faImage } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useFirestore, useStorage } from "reactfire";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { serverTimestamp, updateDoc, getDoc, doc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import React from "react";

import { useNavigate, useParams } from "react-router-dom";
import { Icon } from "@iconify/react";
import openInNewIcon from "@iconify-icons/mdi/open-in-new";

const UpdateEpisodePage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { episodeId, uploadId } = params;

  const [formValues, setFormValues] = useState({
    image: "",
    title: "",
    description: "",
    audio: "",
    freesample: false,
    visibility: "draft",
  });

  const { image, title, description, audio, freesample, visibility } =
    formValues;

  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const storage = useStorage();
  const firestore = useFirestore();

  useEffect(() => {
    getEpisodeDetails(uploadId, episodeId);
  }, [uploadId, episodeId]);

  const getEpisodeDetails = async (uploadId, episodeId) => {
    if (uploadId !== "" && episodeId !== "") {
      const docSnap = await getDoc(
        doc(firestore, "uploads", uploadId, "episodes", episodeId)
      );

      if (docSnap.exists) {
        const data = docSnap.data();
        setFormValues({
          ...formValues,
          title: data.title || "",
          audio: data.audio || "",
          description: data.description || "",
          freesample: data.freesample || false,
          image: data.image || "",
          visibility: data.visibility || "draft",
        });
      } else {
        navigate("/404");
      }
    } else {
      navigate("/404");
    }
  };

  const uploadImage = (file) => {
    if (file !== null && file !== undefined) {
      try {
        const fileName = file.name;

        const fileRef = ref(storage, `coverimages/${uploadId}/${fileName}`);
        const uploadTask = uploadBytesResumable(fileRef, file);

        toast("Please wait, file uploading started.");

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            // const p = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            // console.log('Upload is ' + progress + '% done');
            // switch (snapshot.state) {
            //   case 'paused':
            //     console.log('Upload is paused');
            //     break;
            //   case 'running':
            //     console.log('Upload is running');
            //     break;
            // }
            // setProgress(p);
          },
          (error) => {
            console.log(error);
            toast.error("Error uploading file!");
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              console.log("File available at", downloadURL);
              setFormValues({ ...formValues, image: downloadURL });
              toast.success("File uploaded.");
            });
          }
        );
      } catch (error) {
        console.log(error);
        toast.error("Something went wrong.");
      }
    }
  };

  const uploadAudio = (file) => {
    if (file !== null && file !== undefined) {
      try {
        const fileName = file.name;

        const fileRef = ref(storage, `coverimages/${uploadId}/${fileName}`);
        const uploadTask = uploadBytesResumable(fileRef, file);

        toast("Please wait, file uploading started.");

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            // const p = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            // console.log('Upload is ' + progress + '% done');
            // switch (snapshot.state) {
            //   case 'paused':
            //     console.log('Upload is paused');
            //     break;
            //   case 'running':
            //     console.log('Upload is running');
            //     break;
            // }
            // setProgress(p);
          },
          (error) => {
            console.log(error);
            toast.error("Error uploading file!");
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              console.log("File available at", downloadURL);
              setFormValues({ ...formValues, audio: downloadURL });
              toast.success("File uploaded.");
            });
          }
        );
      } catch (error) {
        console.log(error);
        toast.error("Something went wrong.");
      }
    }
  };

  const btnUpdateEpisodeClick = () => {
    if (title !== "") {
      toast("Please wait, process started");
      updateDoc(doc(firestore, "uploads", uploadId, "episodes", episodeId), {
        title: title,
        description: description,
        image: image,
        audio: audio,
        visibility: visibility,
        lastModified: serverTimestamp(),
        freesample: freesample,
      })
        .then((r) => {
          toast.success("Episode Modified.");
        })
        .catch((e) => {
          console.log(e);
          toast.error("Error while modifying episode.");
        });
    } else {
      toast.error("Please provide title, rest you can update later.");
    }
  };

  return (
    <div>
      <div>Update Episode</div>

      <div className="flex flex-col md:flex-row px-5 py-10 space-x-4">
        <div className="flex-1 p-2">
          <div className="w-72 h-72 bg-gray-200 rounded-lg flex items-center justify-center flex-col">
            {image !== "" ? (
              <img
                src={image}
                className="rounded w-72 h-72 object-cover"
                alt=""
              />
            ) : (
              <>
                <div className="block text-gray-500">
                  <FontAwesomeIcon icon={faImage} />
                </div>
                <div className="text-gray-500 text-center">
                  Choose file to Upload image or Paste URL
                </div>
              </>
            )}
          </div>

          <div className="mt-4">
            Image URL
            <span className="text-gray-400 text-xs">
              - only JPG/JPEG/PNG/GIF Max. 2MB
            </span>
          </div>
          <input
            className="mt-2 bg-gray-200 py-2 px-3 border-0 rounded focus:outline-none w-72 invalid:bg-red-100"
            placeholder="Paste URL here..."
            value={image}
            name="image"
            onChange={handleChange}
            type="url"
          />

          <div className="flex items-center space-x-6 mt-6">
            <label className="block">
              <span className="sr-only">Choose image</span>
              <input
                accept="image/*"
                type="file"
                onChange={(e) => uploadImage(e.target.files[0])}
                className="block w-full text-sm text-slate-500
                    file:mr-4 file:py-2 file:px-4
                    file:rounded-full file:border-0
                    file:text-sm file:font-semibold
                    file:bg-violet-50 file:text-violet-700
                    hover:file:bg-violet-100
                  "
              />
            </label>
          </div>

          <div className="mt-4">Audio File</div>
          <input
            className="mt-2 bg-gray-200 py-2 px-3 border-0 rounded focus:outline-none w-72 invalid:bg-red-100"
            placeholder="URL or choose file to upload"
            value={audio}
            name="audio"
            onChange={handleChange}
            type="url"
          />

          <div className="flex items-center space-x-6 mt-6">
            <label className="block">
              <span className="sr-only">Choose Audio file</span>
              <input
                accept="audio/*"
                type="file"
                onChange={(e) => uploadAudio(e.target.files[0])}
                className="block w-full text-sm text-slate-500
                    file:mr-4 file:py-2 file:px-4
                    file:rounded-full file:border-0
                    file:text-sm file:font-semibold
                    file:bg-violet-50 file:text-violet-700
                    hover:file:bg-violet-100
                  "
              />
            </label>
          </div>

          {audio !== "" ? (
            <div className="mt-6">
              <a
                className="bg-amber-200 text-amber-700 rounded px-4 py-2 hover:bg-amber-300 flex items-center space-x-2 justify-center"
                target="_blank"
                href={audio}
              >
                Open Audio File &nbsp;
                <Icon icon={openInNewIcon} />
              </a>
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className="flex-grow p-2">
          <div className="">
            Title
            <span className="text-gray-400 text-xs ml-2">
              - Only 120 Characters
            </span>
          </div>
          <input
            className="w-full md:w-96 mt-2 bg-gray-200 py-2 px-3 border-0 rounded focus:outline-none  invalid:bg-red-100"
            placeholder="Write Title of Episode"
            value={title}
            name="title"
            onChange={handleChange}
          />

          <div className="mt-4">
            Description
            <span className="text-gray-400 text-xs">
              - You can use emojis, symbol, link
            </span>
          </div>

          <textarea
            className="w-full md:w-96 mt-2 bg-gray-200 py-2 px-3 border-0 rounded focus:outline-none invalid:bg-red-100"
            placeholder="Write description here..."
            rows="8"
            value={description}
            name="description"
            onChange={handleChange}
          />

          <div className="mt-4">Visibility</div>

          <select
            className="w-full md:w-96 mt-2 bg-gray-200 py-2 px-3 border-0 rounded focus:outline-none  invalid:bg-red-100"
            value={visibility}
            name="visibility"
            onChange={handleChange}
          >
            <option value="draft">Draft</option>
            <option value="public">Public</option>
          </select>

          <div className="mt-4">
            Free Sample
            <span className="text-gray-400 text-xs">
              - This will allow user to listen for Free
            </span>
          </div>
          <div className="mt-2">
            <label className="inline-flex">
              <input
                type="checkbox"
                name="freesample"
                className="w-6 h-6 rounded"
                checked={freesample}
                onChange={(e) =>
                  setFormValues({ ...formValues, freesample: e.target.checked })
                }
              />
              <span className="ml-2">
                {freesample ? "This is Free Sample" : ""}
              </span>
            </label>
          </div>

          <div className="mt-6">
            <button
              className="py-2 px-4 bg-violet-500 hover:bg-violet-700 rounded text-white transition-all w-full md:w-96"
              onClick={btnUpdateEpisodeClick}
            >
              Save
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default UpdateEpisodePage;
