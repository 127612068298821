import { Route, Routes } from "react-router-dom";
import { FirebaseAppProvider } from "reactfire";
import { firebaseConfig } from "./firebase/firebase_config";
import FirebaseComponents from "./firebase/firebase_components";
import Page404 from "./pages/page404/page404";
import DashboardPage from "./pages/dashboard/dashboard";
import LoginPage from "./pages/login/login";
import SignupPage from "./pages/login/signup";
import ManageUploadsPage from "./pages/dashboard/manage_uploads/manage_uploads";
import UsersPage from "./pages/dashboard/users/users";
import EditorsPage from "./pages/dashboard/editors/editors";
import SettingsPage from "./pages/dashboard/settings/settings";
import { RequireAuth } from "./utils/RequireAuth";
import EmailVerificationPage from "./pages/login/email_verfication_sent_page";
import NoAccess from "./pages/login/no_access";
import ContactSupportPage from "./pages/dashboard/support/ContactSupportPage";
import ManageCategoryPage from "./pages/dashboard/categories/categories";
import CreateUploadPage from "./pages/dashboard/manage_uploads/CreateUpload";
import DashboardHomePage from "./pages/dashboard/dashboardHome";
import EditUploadPage from "./pages/dashboard/manage_uploads/EditUploadPage";
import ViewEpisodesPage from "./pages/dashboard/manage_uploads/EditUploads/ViewEpisodes";
import CreateEpisodePage from "./pages/dashboard/manage_uploads/EditUploads/CreateEpisodePage";
import UpdateEpisodePage from "./pages/dashboard/manage_uploads/EditUploads/UpdateEpisodePage";
import UpdateUploadPage from "./pages/dashboard/manage_uploads/EditUploads/UpdateUploadPage";
import ForgotPasswordPage from "./pages/login/forgot_password";
import React from "react";

function App() {
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <FirebaseComponents>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/reset" element={<ForgotPasswordPage />} />
          <Route path="/emailsent" element={<EmailVerificationPage />} />
          <Route path="/noaccess" element={<NoAccess />} />
          <Route
            path="/dashboard"
            element={
              <RequireAuth>
                <DashboardPage />
              </RequireAuth>
            }
          >
            <Route path="" element={<DashboardHomePage />} />
            <Route
              path="manageuploads"
              element={
                <RequireAuth>
                  <ManageUploadsPage />
                </RequireAuth>
              }
            />
            <Route
              path="users"
              element={
                <RequireAuth>
                  <UsersPage />
                </RequireAuth>
              }
            />
            <Route
              path="editors"
              element={
                <RequireAuth>
                  <EditorsPage />
                </RequireAuth>
              }
            />
            <Route
              path="settings"
              element={
                <RequireAuth>
                  <SettingsPage />
                </RequireAuth>
              }
            />
            <Route
              path="categories"
              element={
                <RequireAuth>
                  <ManageCategoryPage />
                </RequireAuth>
              }
            />

            <Route
              path="manageuploads/create"
              element={
                <RequireAuth>
                  <CreateUploadPage />
                </RequireAuth>
              }
            />

            <Route
              path="uploads/:uploadId"
              element={
                <RequireAuth>
                  <EditUploadPage />
                </RequireAuth>
              }
            >
              <Route
                path=""
                element={
                  <RequireAuth>
                    <UpdateUploadPage />
                  </RequireAuth>
                }
              />
              <Route
                path="episodes"
                element={
                  <RequireAuth>
                    <ViewEpisodesPage />
                  </RequireAuth>
                }
              />
              <Route
                path="episodes/create"
                element={
                  <RequireAuth>
                    <CreateEpisodePage />
                  </RequireAuth>
                }
              />
              <Route
                path="episodes/:episodeId/update"
                element={
                  <RequireAuth>
                    <UpdateEpisodePage />
                  </RequireAuth>
                }
              />
            </Route>

            <Route path="support" element={<ContactSupportPage />} />
          </Route>

          <Route path="*" element={<Page404 />} />
          <Route path="/404" element={<Page404 />} />
        </Routes>
      </FirebaseComponents>
    </FirebaseAppProvider>
  );
};

export default App;
