import { doc } from "firebase/firestore";
import React,{ createContext } from "react";
import { useLocation, Navigate } from "react-router-dom";
import {
  useSigninCheck,
  useFirestore,
  useFirestoreDocDataOnce,
} from "reactfire";

export const UserDataContext = createContext({
  name: "",
  email: "",
  role: "",
});

export function RequireAuth({ children }) {
  const location = useLocation();

  const { status, data: signInCheckResult } = useSigninCheck();

  if (status === "loading") {
    return <span>loading...</span>;
  }

  if (signInCheckResult.signedIn === false) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  if (signInCheckResult.user.emailVerified) {
    // check credentials
    return (
      <UserAuthDetailsWithChild
        child={children}
        user={signInCheckResult.user}
      />
    );
  } else {
    return <Navigate to="/emailsent" state={{ from: location }} />;
  }
};

const UserAuthDetailsWithChild = ({ child, user }) => {
  const location = useLocation();

  const firestore = useFirestore();
  const userRef = doc(firestore, "users", user.uid);
  const { status, data: userData } = useFirestoreDocDataOnce(userRef);

  if (status === "loading") {
    return <span>loading...</span>;
  }
  console.log(userData);
  if (userData.role === "admin" || userData.role === "editor") {
    return (
      <UserDataContext.Provider
        value={{
          email: userData.email,
          role: userData.role,
          name: userData.name,
        }}
      >
        {child}
      </UserDataContext.Provider>
    );
  }
  return <Navigate to="/noaccess" state={{ from: location }} />;
};
