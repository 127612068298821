import React from "react";
import { Outlet, useParams } from "react-router-dom";
import EditUploadSidebarComponent from "./EditUploads/EditUploadSidebar";
const EditUploadPage = () => {
  const params = useParams();

  const { uploadId } = params;

  return (
    <div className="flex space-x-2">
      <EditUploadSidebarComponent />
      <Outlet />
    </div>
  );
};

export default EditUploadPage;
