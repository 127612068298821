import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt, faEnvelopeOpen } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const ContactSupportPage = () => {
  return (
    <div className="container h-screen">
      <div className="text-2xl">Contact Support</div>

/*      <div className="mt-8 ml-4 bg-orange-50 rounded-lg p-8 h-96 w-fit">
        <a
          href="mailto:support@coderqueenhelp.freshdesk.com"
          className="block text-lg text-indigo-500 hover:text-indigo-800"
        >
          <FontAwesomeIcon icon={faEnvelopeOpen} />
          &nbsp; support@coderqueenhelp.freshdesk.com
        </a>
        <a
          href="http://wa.me/919499888170"
          className="block text-lg text-green-600 hover:text-green-800 mt-4"
        >
          <FontAwesomeIcon icon={faWhatsapp} />
          &nbsp; +91 9499888170
        </a>
      </div>*/
    </div>
  );
};

export default ContactSupportPage;
