import React from "react";
import { faImage } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFirestore } from "reactfire";
import { Button } from "../../../../components/button";

const ViewEpisodesPage = () => {
  const navigate = useNavigate();
  const { uploadId } = useParams();
  const firestore = useFirestore();

  const [state, setState] = useState({
    episodes: [],
    lastViewableEpisodeDoc: null,
  });

  const { episodes, lastViewableEpisodeDoc } = state;

  const episodesCollection = collection(
    firestore,
    "uploads",
    uploadId,
    "episodes"
  );
  const episodesQuery = query(
    episodesCollection,
    orderBy("date", "asc"),
    limit(10)
  );

  useEffect(() => {
    getEpisodes();
  }, [uploadId]);

  const getEpisodes = async () => {
    const episodesSnap = await getDocs(episodesQuery);

    if (!episodesSnap.empty) {
      const episodeDocs = episodesSnap.docs;

      setState({
        ...state,
        episodes: episodeDocs,
        lastViewableEpisodeDoc: episodesSnap.docs[episodesSnap.docs.length - 1],
      });
    } else {
    }
  };

  const getMoreEpisodes = async () => {
    //

    const episodesMoreQuery = query(
      episodesCollection,
      orderBy("date", "asc"),
      startAfter(lastViewableEpisodeDoc),
      limit(10)
    );

    const episodesSnap = await getDocs(episodesMoreQuery);

    if (!episodesSnap.empty) {
      const episodeDocs = episodesSnap.docs;

      const newEpisodes = episodes.concat(episodeDocs);

      setState({
        ...state,
        episodes: newEpisodes,
        lastViewableEpisodeDoc: episodesSnap.docs[episodesSnap.docs.length - 1],
      });
    } else {
    }
  };

  return (
    <div>
      <div>View Episodes</div>

      <Button
        child="Create Episode"
        onClick={() => {
          navigate("create");
        }}
      />

      <div className="mt-4">
        {episodes.length > 0 ? (
          <div>
            {episodes.map((episode) => (
              <EpisodeItemCard
                date={episode.data().date}
                title={episode.data().title}
                description={episode.data().description}
                image={episode.data().image}
                visibility={episode.data().visibility}
                freesample={episode.data().freesample}
                key={episode.id}
                onUpdateTap={() => {
                  navigate(`${episode.id}/update`)
                }}
              />
            ))}

            <div className="text-center">
              <button
                className="mt-4 bg-amber-500 hover:bg-amber-600 text-white py-2 px-4  rounded-full "
                onClick={getMoreEpisodes}
              >
                Load more
              </button>
            </div>
          </div>
        ) : (
          <>
            <div>Create episodes, it will appear here.</div>
          </>
        )}
      </div>
    </div>
  );
};

const EpisodeItemCard = ({
  title,
  description,
  image,
  visibility,
  freesample = false,
  date,
  onUpdateTap,
}) => {
  const dateObj = date.toDate();
  

  return (
    <div className="w-full  space-x-4 py-4 px-4 border border-slate-100 rounded-lg mt-4 mx-4 flex bg-white">
      {/* image */}
      <div className="w-16 h-16 bg-slate-100 rounded flex-shrink-0 flex items-center justify-center text-slate-400">
        {image !== "" && image !== undefined ? (
          <img
            src={image}
            className="w-16 h-16 rounded object-cover"
            alt="Audiobook episode cover"
          />
        ) : (
          <FontAwesomeIcon icon={faImage} />
        )}
      </div>
      {/* image */}

      {/* details */}
      <div className="space-y-1 flex-grow">
        <div className="text-xl font-bold">{title}</div>
        <div className="text-sm text-gray-500">{description}</div>
        <div className="text-sm text-gray-500">{dateObj.toString()}</div>

        <div className="flex flex-row space-x-2">
          {freesample ? (
            <div className="bg-amber-200 text-amber-600 px-2 py-1 rounded">
              Free Sample
            </div>
          ) : (
            <></>
          )}
          {visibility === "draft" ? (
            <div className="bg-blue-200 text-blue-600 px-2 py-1 rounded">
              Draft
            </div>
          ) : (
            <></>
          )}
          {visibility === "public" ? (
            <div className="bg-green-200 text-green-600 px-2 py-1 rounded">
              Public
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      {/* details */}

      {/* action buttons */}
      <div className="flex flex-col space-y-2">
        {/* <button
      className="bg-gray-100 hover:bg-gray-300 text-gray-600 rounded px-4 py-2"
      onClick={onViewEpisodeTap}
    >
      VIEW EPISODES
    </button> */}
        <button
          className="bg-gray-100 hover:bg-gray-300 text-gray-600 rounded px-4 py-2"
          onClick={onUpdateTap}
        >
          UPDATE
        </button>
        {/* <button
      className="bg-red-500 hover:bg-red-700 text-white rounded px-4 py-2"
      onClick={onRemoveTap}
    >
      REMOVE
    </button> */}
      </div>
      {/* action buttons */}
    </div>
  );
};

export default ViewEpisodesPage;
