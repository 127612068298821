import React from "react";
function page404() {
  return (
    <div className="flex h-screen text-center">
      <div className="m-auto">
        <img src="/assets/logo.svg" alt="logo" className="h-20" />

        <p className="italic py-2">
          The page you're looking for is not available.
        </p>
      </div>
    </div>
  );
}

export default page404;
