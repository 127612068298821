import React from "react";
import { Icon } from "@iconify/react";
import adminIcon from "@iconify-icons/mdi/administrator";
import Header from "../../components/header";
const NoAccessPage = () => {
  return (
    <div>
      <Header />
      <div className="flex h-screen text-center flex-col items-center justify-center">
        <Icon
          icon={adminIcon}
          height="200"
          width="200"
          className="text-orange-500 inline-block"
        />
        <div className="mt-2 text-2xl italic font-light">
          Currently, you don't have access to this page. Requires permission
          from Admin User to get Access.
        </div>
      </div>
    </div>
  );
};

export default NoAccessPage;
