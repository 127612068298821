import { Link, useLocation, useParams } from "react-router-dom";
import { editUploadSidebarPaths } from "./EditUploadSidebarConfig";
import React from "react";

const EditUploadSidebarComponent = () => {
  const { pathname } = useLocation();
  const { uploadId } = useParams();

  console.log(pathname);

  return (
    <div className="flex flex-col  p-4 bg-white border-r overflow-scroll">
      {/* <img src="/assets/logo.svg" alt="logo" className="h-12" /> */}

      {editUploadSidebarPaths.map((link) => {
        const navpath = link.path.replace(":uploadId", uploadId);
        return (
          <Link
            key={navpath}
            to={navpath}
            className={
              navpath === pathname
                ? "mt-2 w-full py-2 px-3 flex flex-row align-middle rounded-lg text-yellow-600 bg-yellow-100"
                : "mt-2 w-full py-2 px-3 flex flex-row align-middle rounded-lg hover:bg-gray-100 text-gray-400"
            }
          >
            <div className="text-sm">{link.icon}</div>{" "}
            <div className="ml-1 text-sm">{link.title}</div>
          </Link>
        );
      })}
    </div>
  );
};

export default EditUploadSidebarComponent;
