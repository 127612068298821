import { Icon } from "@iconify/react";
import React from "react";
import logoutIcon from "@iconify-icons/mdi/logout";
import { useAuth } from "reactfire";
import { useNavigate } from "react-router-dom";
const Header = () => {
  const auth = useAuth();
  const navigate = useNavigate()

  const logout = async () => {
    await auth.signOut();
    navigate("/");
  };

  return (
    <div className="fixed top-0 right-0 p-4">
      <button
        className="rounded-full bg-orange-600 hover:bg-orange-700 py-2 px-4 text-white flex flex-row items-center"
        onClick={logout}
      >
        <Icon icon={logoutIcon} /> Logout
      </button>
    </div>
  );
};

export default Header;
