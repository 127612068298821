import React,{ useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth, useFirestore } from "reactfire";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";

const SignupPage = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const firestore = useFirestore();

  const [state, setState] = useState({
    name: "",
    email: "",
    password: "",
    errorTxt: "",
  });

  const { name, email, password, errorTxt } = state;

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const btnSignupClick = async () => {
    if (name !== "" && email !== "" && password !== "") {
      try {
        const userCred = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        if (userCred.user !== null && userCred.user !== undefined) {
          // add info to firestore collection
          await setDoc(doc(firestore, "users", userCred.user.uid), {
            name: name,
            email: email,
            role: "listener",
          });
          if (userCred.user.emailVerified) {
            navigate("/");
          } else {
            await sendEmailVerification(userCred.user);
            navigate("/emailsent");
          }
        }
      } catch (e) {
        const message = e.message;
        console.log(e);
        setState({
          ...state,
          errorTxt: message,
        });
      }
    } else {
      setState({
        ...state,
        errorTxt: "Please provide all details...",
      });
    }
  };

  return (
    <div className="flex flex-col sm:flex-row">
      <div className="flex-1 p-6 ">
        <div className="hero container max-w-screen-lg mx-auto pb-10 flex flex-col">
          <img src="/assets/logo.svg" className="h-16 mx-auto" />
          <div className="text-4xl my-6 text-center">Signup</div>

          {/* form */}
          <div className="container w-3/4 self-center">
            <div>Name</div>
            <input
              type="text"
              placeholder="Your Name here..."
              className="mt-2 block w-full px-3 py-2 bg-gray-100 rounded  placeholder-gray-400
        focus:outline-none "
              name="name"
              value={name}
              onChange={handleChange}
            />
            <div className="mt-4">EMAIL</div>
            <input
              type="email"
              placeholder="Your Email here..."
              className="mt-2 block w-full px-3 py-2 bg-gray-100 rounded  placeholder-gray-400
        focus:outline-none "
              name="email"
              value={email}
              onChange={handleChange}
            />
            <div className="mt-4">PASSWORD</div>
            <input
              placeholder="Your password here..."
              type="password"
              className="mt-2 block w-full px-3 py-2 bg-gray-100 rounded  placeholder-gray-400
                focus:outline-none"
              name="password"
              value={password}
              onChange={handleChange}
            />
            {errorTxt !== "" ? (
              <div className="mt-2 text-red-500">{errorTxt}</div>
            ) : (
              <></>
            )}

            <button
              onClick={btnSignupClick}
              className="block w-full mt-6 rounded px-4 py-2 text-white bg-orange-500 hover:bg-orange-400 active:bg-orange-600 focus:outline-none focus:ring focus:ring-orange-300"
            >
              Signup
            </button>

            <div className="flex flex-row justify-between mt-6">
              <div className="text-gray-500">Already Have account</div>
              <Link className=" text-blue-600 hover:text-blue-900" to="/login">
                Login
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-1 p-6 h-screen bg-gradient-to-br from-yellow-400 to-pink-600">
        <div className="text-4xl text-white font-bold">Contact Support</div>
        <div className="text-white mt-4">
          We provide Customization, UI &amp; UX Design Service, App Development
          Service.
        </div>
        <div className="mt-6 flex flex-col w-1/2">
          <a
            className=" w-auto cursor-pointer text-white"
          >
          </a>
          <a
            className=" w-auto cursor-pointer text-white"
          >
          </a>
          <a
            className=" w-auto cursor-pointer text-white"
          >
          </a>
        </div>
      </div>
    </div>
  );
};

export default SignupPage;
