import { Icon } from "@iconify/react";
import React from "react";
import editIcon from "@iconify-icons/mdi/edit-outline";
import episodesIcon from "@iconify-icons/mdi/playlist-music-outline";
import analyticsIcon from "@iconify-icons/mdi/analytics";

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <Icon className="inline-block" icon={name} width={22} height={22} />
);

export const editUploadSidebarPaths = [
  {
    title: "Modify",
    path: "/dashboard/uploads/:uploadId",
    icon: getIcon(editIcon),
  },
  {
    title: "Episodes",
    path: "/dashboard/uploads/:uploadId/episodes",
    icon: getIcon(episodesIcon),
  },
  // {
  //   title: "Analytics",
  //   path: "/dashboard/uploads/:uploadId/analytics",
  //   icon: getIcon(analyticsIcon),
  // },
];
