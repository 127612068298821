import { faImage } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import React from "react";

import {
  useFirestore,
  useFirestoreCollectionData,
  useStorage,
} from "reactfire";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  orderBy,
  collection,
  query,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import slugify from "slugify";

/*
Form fields: 
image, title, description, speaker (image, id, name), author(image, id, name),
category, slug, 
visibility (draft/public), 
date, featured, 
*/
const CreateUploadPage = () => {
  const [formValues, setFormValues] = useState({
    image: "",
    title: "",
    description: "",
    category: "",
    uploadType: "audiobook",
    free: false,
  });

  const { image, title, description, category, uploadType, free } = formValues;

  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const storage = useStorage();
  const firestore = useFirestore();
  const categoriesCollection = collection(firestore, "categories");
  const categoriesQuery = query(
    categoriesCollection,
    orderBy("category", "asc")
  );

  const { status, data: categories } = useFirestoreCollectionData(
    categoriesQuery,
    { idField: "id" }
  );

  const uploadImage = (file) => {
    if (file !== null && file !== undefined) {
      try {
        const fileName = file.name;

        const fileRef = ref(storage, `coverimages/${fileName}`);
        const uploadTask = uploadBytesResumable(fileRef, file);

        toast("Please wait, file uploading started.");

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            // const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            // console.log('Upload is ' + progress + '% done');
            // switch (snapshot.state) {
            //   case 'paused':
            //     console.log('Upload is paused');
            //     break;
            //   case 'running':
            //     console.log('Upload is running');
            //     break;
            // }
            // setProgress(p);
          },
          (error) => {
            console.log(error);
            toast.error("Error uploading file!");
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              console.log("File available at", downloadURL);
              setFormValues({ ...formValues, image: downloadURL });
              toast.success("File uploaded.");
            });
          }
        );
      } catch (error) {
        console.log(error);
        toast.error("Something went wrong.");
      }
    }
  };

  const btnCreateUploadClick = () => {
    if (
      title !== "" &&
      description !== "" &&
      category !== "" &&
      image !== "" &&
      uploadType != ""
    ) {
      const slug = slugify(title, {
        lower: true,
        strict: true,
      });

      const visibility = "draft";

      toast("Please wait, process started");

      addDoc(collection(firestore, "uploads"), {
        title: title,
        description: description,
        category: category,
        image: image,
        slug: slug,
        visibility: visibility,
        date: serverTimestamp(),
        featured: false,
        uploadType: uploadType,
        free: free,
      })
        .then((r) => {
          toast.success("Audiobook/Podcast Created.");
        })
        .catch((e) => {
          console.log(e);
          toast.error("Error while creating upload.");
        });
    } else {
      toast.error("Please fill all details.");
    }
  };

  return (
    <div className=" ">
      <div>Create Upload</div>

      <div className="flex flex-col md:flex-row px-5 py-10">
        <div className="flex-1 p-2">
          <div className="w-72 h-72 bg-gray-200 rounded-lg flex items-center justify-center flex-col">
            {image !== "" ? (
              <img
                src={image}
                className="rounded w-72 h-72 object-cover"
                alt="audiobook/podcast"
              />
            ) : (
              <>
                <div className="block text-gray-500">
                  <FontAwesomeIcon icon={faImage} />
                </div>
                <div className="text-gray-500 text-center">
                  Choose file to Upload image or Paste URL
                </div>
              </>
            )}
          </div>

          <div className="mt-4">
            Image URL
            <span className="text-gray-400 text-xs">
              - only JPG/JPEG/PNG/GIF Max. 2MB
            </span>
          </div>
          <input
            className="mt-2 bg-gray-200 py-2 px-3 border-0 rounded focus:outline-none w-72 invalid:bg-red-100"
            placeholder="Paste URL here..."
            value={image}
            name="image"
            onChange={handleChange}
            type="url"
          />

          <div className="flex items-center space-x-6 mt-6">
            <label className="block">
              <span className="sr-only">Choose image</span>
              <input
                accept="image/*"
                type="file"
                onChange={(e) => uploadImage(e.target.files[0])}
                className="block w-full text-sm text-slate-500
                    file:mr-4 file:py-2 file:px-4
                    file:rounded-full file:border-0
                    file:text-sm file:font-semibold
                    file:bg-violet-50 file:text-violet-700
                    hover:file:bg-violet-100
                  "
              />
            </label>
          </div>
        </div>
        <div className="flex-grow p-2">
          <div className="">
            Title
            <span className="text-gray-400 text-xs ml-2">
              - Only 120 Characters
            </span>
          </div>
          <input
            className="w-full md:w-96 mt-2 bg-gray-200 py-2 px-3 border-0 rounded focus:outline-none  invalid:bg-red-100"
            placeholder="Write Title of Podcast / Audiobook"
            value={title}
            name="title"
            onChange={handleChange}
          />

          <div className="mt-4">
            Description
            <span className="text-gray-400 text-xs">
              - You can use emojis, symbol, link
            </span>
          </div>

          <textarea
            className="w-full md:w-96 mt-2 bg-gray-200 py-2 px-3 border-0 rounded focus:outline-none invalid:bg-red-100"
            placeholder="Write description here..."
            rows="8"
            value={description}
            name="description"
            onChange={handleChange}
          />

          <div className="mt-4">Category</div>

          {status === "success" ? (
            <select
              className="w-full md:w-96 mt-2 bg-gray-200 py-2 px-3 border-0 rounded focus:outline-none  invalid:bg-red-100"
              value={category}
              name="category"
              onChange={handleChange}
            >
              {categories.map((c) => (
                <option key={c.id} value={c.id}>
                  {c.category}
                </option>
              ))}
            </select>
          ) : (
            <></>
          )}

          <div className="mt-4">
            Upload Type
            <span className="text-gray-400 text-xs">
              - specify, is it AudioBook or Podcast?
            </span>
          </div>

          <select
            className="w-full md:w-96 mt-2 bg-gray-200 py-2 px-3 border-0 rounded focus:outline-none  invalid:bg-red-100"
            value={uploadType}
            name="uploadType"
            onChange={handleChange}
          >
            <option key="audiobook" value="audiobook">
              AudioBook
            </option>
            <option key="podcast" value="podcast">
              Podcast
            </option>
          </select>

          <div className="mt-4">
            Free?
            <span className="text-gray-400 text-xs">
              - if you mark this, upload will be Free to access.
            </span>
          </div>
          <div className="mt-2">
            <label className="inline-flex">
              <input
                type="checkbox"
                name="free"
                className="w-6 h-6 rounded"
                checked={free}
                onChange={(e) =>
                  setFormValues({ ...formValues, free: e.target.checked })
                }
              />
              <span className="ml-2">
                {free ? "This Upload has Free Access" : ""}
              </span>
            </label>
          </div>

          <div className="mt-6">
            <button
              className="py-2 px-4 bg-violet-500 hover:bg-violet-700 rounded text-white transition-all w-full md:w-96"
              onClick={btnCreateUploadClick}
            >
              Create &amp; Save Draft
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default CreateUploadPage;
