import React from "react";
import { getAuth } from "firebase/auth"; // Firebase v9+
import { getFirestore } from "firebase/firestore"; // Firebase v9+
import { getStorage } from "firebase/storage";

import {
  FirestoreProvider,
  AuthProvider,
  useFirebaseApp,
  StorageProvider,
} from "reactfire";

export default function FirebaseComponents({ children }) {
  const app = useFirebaseApp(); // a parent component contains a `FirebaseAppProvider`

  // initialize Database and Auth with the normal Firebase SDK functions
  const firestore = getFirestore(app);
  const auth = getAuth(app);
  const storage = getStorage(app);

  // any child components will be able to use `useUser`, `useDatabaseObjectData`, etc
  return (
    <AuthProvider sdk={auth}>
      <StorageProvider sdk={storage}>
        <FirestoreProvider sdk={firestore}>{children}</FirestoreProvider>
      </StorageProvider>
    </AuthProvider>
  );
}
