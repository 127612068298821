import React from "react";
const SettingsPage = () =>{
    return <div className="h-screen"> 
        <div>Settings </div>

        <div className="mt-4">
            
        </div>
    </div>
};

export default SettingsPage;
