import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React from "react";

import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { DangerButton, InfoButton } from "../../../components/button";
import {
  useFirestore,
  useFirestoreCollectionData,
  useStorage,
} from "reactfire";
import {
  collection,
  deleteDoc,
  doc,
  orderBy,
  query,
  setDoc,
} from "firebase/firestore";
import slugify from "slugify";
import { ShimmerLoading } from "../../../components/shimmerloading";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ManageCategoryPage = () => {
  const storage = useStorage();
  const firestore = useFirestore();
  const categoriesCollection = collection(firestore, "categories");
  const categoriesQuery = query(
    categoriesCollection,
    orderBy("category", "asc")
  );

  const { status, data: categories } = useFirestoreCollectionData(
    categoriesQuery,
    { idField: "id" }
  );

  const [state, setState] = useState({
    categoryTitleTxt: "",
    imageURL: "",
  });

  const handleFormChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const { categoryTitleTxt, imageURL } = state;

  const createCategoryBtnClick = () => {
    if (categoryTitleTxt !== "" && imageURL !== "") {
      const slug = slugify(categoryTitleTxt, {
        lower: true,
        strict: true,
      });
      setDoc(doc(firestore, "categories", slug), {
        category: categoryTitleTxt,
        slug: slug,
        image: imageURL,
      })
        .then(() => {
          toast.success("Category added.");
        })
        .catch((error) => {
          console.log(error);
          toast.error("Something went wrong, try again");
        });
    } else {
      toast.error("Provide Category title and select image!");
    }
  };

  const uploadCategoryImage = (file) => {
    if (file !== null && file !== undefined) {
      try {
        const fileName = file.name;

        const fileRef = ref(storage, `categories/${fileName}`);
        const uploadTask = uploadBytesResumable(fileRef, file);

        toast("Please wait, file uploading started.");

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const p = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            // console.log('Upload is ' + progress + '% done');
            // switch (snapshot.state) {
            //   case 'paused':
            //     console.log('Upload is paused');
            //     break;
            //   case 'running':
            //     console.log('Upload is running');
            //     break;
            // }
            // setProgress(p);
          },
          (error) => {
            console.log(error);
            toast.error("Error uploading file!");
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              console.log("File available at", downloadURL);
              setState({ ...state, imageURL: downloadURL });
              toast.success("File uploaded.");
            });
          }
        );
      } catch (error) {
        console.log(error);
        toast.error("Something went wrong.");
      }
    }
  };

  return (
    <div>
      <div>Categories</div>

      <div className="flex flex-col sm:flex-row mt-2">
        <div className="flex-shrink-0 p-4">
          <div className="mt-4">
            <div className="mb-1">Category Title</div>
            <input
              placeholder="Write category title here..."
              type="text"
              className="py-2 px-3 border-0 rounded bg-gray-100 focus:outline-none w-72 invalid:bg-red-100"
              name="categoryTitleTxt"
              value={categoryTitleTxt}
              onChange={handleFormChange}
            />

            <div className="mt-3">Category Image</div>
            <input
              placeholder="Paste URL or Choose File"
              type="url"
              className="py-2 px-3 border-0 rounded bg-gray-100 focus:outline-none w-72 invalid:bg-red-100"
              name="imageURL"
              value={imageURL}
              onChange={handleFormChange}
            />

            <div className="flex items-center space-x-6 mt-4">
              <label className="block">
                <span className="sr-only">Choose category photo</span>
                <input
                  accept="image/*"
                  type="file"
                  onChange={(e) => uploadCategoryImage(e.target.files[0])}
                  className="block w-full text-sm text-slate-500
                    file:mr-4 file:py-2 file:px-4
                    file:rounded-full file:border-0
                    file:text-sm file:font-semibold
                    file:bg-violet-50 file:text-violet-700
                    hover:file:bg-violet-100
                  "
                />
              </label>
            </div>
          </div>

          <div className="mt-3">
            <InfoButton child="Create" onClick={createCategoryBtnClick} />
          </div>
        </div>
        <div className="flex-1 p-4">
          {/* fetch categories */}
          {status === "loading" && status !== "error" ? (
            <>
              <ShimmerLoading />
            </>
          ) : (
            <>
              {categories.map((category) => {
                return (
                  <div
                    className="border border-slate-100 px-4 py-5 rounded mb-2 flex flex-row space-x-2"
                    key={category.id}
                  >
                    <div className="flex">
                      <img
                        src={category.image}
                        className="w-24 h-24 object-cover rounded"
                      />
                    </div>
                    <div className="flex-grow flex-col">
                      <div className="font-bold">{category.category}</div>
                      <div>{category.slug}</div>
                    </div>
                    <div className="flex-shrink-0">
                      <button
                        onClick={() => {
                          toast("Please wait...");
                          deleteDoc(doc(firestore, "categories", category.id))
                            .then((v) => {
                              toast.success("Category deleted");
                            })
                            .catch((e) => {
                              console.log(e);
                              toast.error("Something went wrong!");
                            });
                        }}
                        className="w-12 h-12 rounded-full bg-red-100 text-red-500 hover:bg-red-300 hover:text-red-700"
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </button>
                    </div>
                  </div>
                );
              })}
            </>
          )}
          {/* fetch categories */}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ManageCategoryPage;
