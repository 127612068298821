import React from "react";
const DashboardHomePage = () => {
  return (
    <div className="container h-screen">
      <div className="mt-4 text-4xl">Hi 👋, Welcome to Admin Panel</div>
    </div>
  );
};

export default DashboardHomePage;
