import { faEye, faEyeSlash, faMicrophoneLines, faPodcast, faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
} from "firebase/firestore";
import React,{ useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFirestore } from "reactfire";
import { Button } from "../../../components/button";

const ManageUploadsPage = () => {
  const navigate = useNavigate();
  const firestore = useFirestore();

  const [state, setState] = useState({
    uploads: [],
    lastUploadDocument: null,
  });

  const { uploads, lastUploadDocument } = state;

  const uploadsQuery = query(
    collection(firestore, "uploads"),
    orderBy("date", "desc"),
    limit(10)
  );

  // const { status, data: uploads } = useFirestoreCollectionData(uploadsQuery, {
  //   idField: "id",
  // });

  useEffect(() => {
    getUploads();
  }, []);

  const getUploads = async () => {
    const docSnap = await getDocs(uploadsQuery);
    if (!docSnap.empty) {
      setState({
        ...state,
        uploads: docSnap.docs,
        lastUploadDocument: docSnap.docs[docSnap.docs.length - 1],
      });
    }
  };

  const getMoreUploads = async () => {
    const uploadsMoreQuery = query(
      collection(firestore, "uploads"),
      orderBy("date", "desc"),
      startAfter(lastUploadDocument),
      limit(10)
    );
    const docSnap = await getDocs(uploadsMoreQuery);
    if (!docSnap.empty) {
      setState({
        ...state,
        uploads: uploads.concat(docSnap.docs),
        lastUploadDocument: docSnap.docs[docSnap.docs.length - 1],
      });
    }
  };

  return (
    <div>
      <div>Manage Uploads</div>

      <div className="container mt-4">
        <Button
          child="Create Audiobook / Podcast"
          onClick={() => {
            //
            navigate("create");
          }}
        />

        {uploads.length > 0 ? (
          <div>
            {uploads.map((upload) => {
              return UploadItemCard({
                title: upload.data().title,
                category: upload.data().category || "",
                date: upload.data().date,
                isFeatured: upload.data().featured || false,
                visibility: upload.data().visibility || "",
                image: upload.data().image || "",
                free: upload.data().free || false,
                uploadType: upload.data().uploadType || "",
                onUpdateTap: () => {
                  navigate(`/dashboard/uploads/${upload.id}`);
                },
                onViewEpisodeTap: () => {
                  navigate(`/dashboard/uploads/${upload.id}/episodes`);
                },
              });
            })}

            <div className="text-center">
              <button
                className="mt-4 bg-amber-500 hover:bg-amber-600 text-white py-2 px-4  rounded-full "
                onClick={getMoreUploads}
              >
                Load more
              </button>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

const UploadItemCard = ({
  title,
  category,
  date,
  isFeatured = false,
  visibility,
  image,
  free = false,
  uploadType,
  onViewEpisodeTap,
  onUpdateTap,
}) => {
  return (
    <div className="w-full md:w-3/4 space-x-4 py-4 px-4 border border-slate-100 rounded-lg mt-4 mx-4 flex bg-white">
      {/* image */}
      <div className="w-32 h-32 bg-slate-100 rounded">
        <img
          src={image}
          className="w-32 h-32 rounded object-cover"
          alt="Audiobook image"
        />
      </div>
      {/* image */}

      {/* details */}
      <div className="space-y-1 flex-grow">
        <div className="text-xl font-bold">{title}</div>
        <div className="text-sm text-gray-500">Category: {category}</div>
        <div className="text-sm text-gray-500">{date.toDate().toString()}</div>

        <div className="flex flex-row space-x-2">
          {isFeatured ? (
            <div className="bg-amber-100 text-amber-500 px-2 py-1 rounded">
              <FontAwesomeIcon icon={faStar} /> Featured
            </div>
          ) : (
            <></>
          )}
          {visibility === "draft" ? (
            <div className="bg-blue-100 text-blue-500 px-2 py-1 rounded">
              <FontAwesomeIcon icon={faEyeSlash} /> Draft
            </div>
          ) : (
            <></>
          )}
          {visibility === "public" ? (
            <div className="bg-green-100 text-green-500 px-2 py-1 rounded">
              <FontAwesomeIcon icon={faEye} /> Public
            </div>
          ) : (
            <></>
          )}
          
          {uploadType === "audiobook" ? (
            <div className=" text-green-500 px-2 py-1 rounded border-b-4 border-green-500 hover:bg-green-300 hover:text-green-800 transition-all duration-300">
              <FontAwesomeIcon icon={faMicrophoneLines} /> AudioBook
            </div>
          ) : (
            <></>
          )}
          {uploadType === "podcast" ? (
            <div className=" text-green-500 px-2 py-1 rounded border-b-4 border-green-500 hover:bg-green-300 hover:text-green-800 transition-all duration-300">
              <FontAwesomeIcon icon={faPodcast} /> Podcast
            </div>
          ) : (
            <></>
          )}
          {free ? (
            <div className="text-lime-500 px-2 py-1 rounded border-b-4 border-lime-500 hover:bg-lime-300 hover:text-lime-800 transition-all duration-300">
              Free
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      {/* details */}

      {/* action buttons */}
      <div className="flex flex-col space-y-2">
        <button
          className="bg-gray-100 hover:bg-gray-300 text-gray-600 rounded px-4 py-2"
          onClick={onViewEpisodeTap}
        >
          VIEW EPISODES
        </button>
        <button
          className="bg-gray-100 hover:bg-gray-300 text-gray-600 rounded px-4 py-2"
          onClick={onUpdateTap}
        >
          UPDATE
        </button>
        {/* <button
          className="bg-red-500 hover:bg-red-700 text-white rounded px-4 py-2"
          onClick={onRemoveTap}
        >
          REMOVE
        </button> */}
      </div>
      {/* action buttons */}
    </div>
  );
};

export default ManageUploadsPage;
