import React,{ useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth, useSigninCheck } from "reactfire";
import { signInWithEmailAndPassword } from "firebase/auth";

const LoginPage = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const { status, data: signInCheckResult } = useSigninCheck();

  const [state, setState] = useState({
    email: "",
    password: "",
    errorTxt: "",
  });

  const { email, password, errorTxt } = state;

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const btnLoginClick = () => {
    // login
    signInWithEmailAndPassword(auth, email, password)
      .then((userCred) => {
        console.log(userCred.user);
      })
      .catch((e) => {
        console.log(e);
        setState({ ...state, errorTxt: "Please provide correct info." });
      });
  };

  if (status === "loading") {
    return <span>loading...</span>;
  }

  if (signInCheckResult.signedIn === true) {
    navigate("/dashboard");
  }

  return (
    <div className="flex flex-col sm:flex-row">
      <div className="flex-1 p-6 ">
        <div className="hero container max-w-screen-lg mx-auto pb-10 flex flex-col">
          <img src="/assets/logo.svg" className="h-16 mx-auto" />
          <div className="text-4xl my-6 text-center">Login</div>

          {/* form */}
          <div className="container w-3/4 self-center">
            <div>EMAIL</div>
            <input
              type="email"
              placeholder="Your Email here..."
              className="mt-2 block w-full px-3 py-2 bg-gray-100 rounded  placeholder-gray-400
      focus:outline-none "
              value={email}
              name="email"
              onChange={handleChange}
            />
            <div className="mt-4">PASSWORD</div>
            <input
              placeholder="Your password here..."
              type="password"
              className="mt-2 block w-full px-3 py-2 bg-gray-100 rounded  placeholder-gray-400
              focus:outline-none"
              value={password}
              name="password"
              onChange={handleChange}
            />

            {errorTxt !== "" ? (
              <div className="mt-2 text-red-500">{errorTxt}</div>
            ) : (
              <></>
            )}

            <button
              className="block w-full mt-6 rounded px-4 py-2 text-white bg-orange-500 hover:bg-orange-400 active:bg-orange-600 focus:outline-none focus:ring focus:ring-orange-300"
              onClick={btnLoginClick}
            >
              LOGIN
            </button>


            <div className="flex flex-row justify-between mt-4">
              <div className="text-gray-500">Forgot Password?</div>
              <Link
                className=" text-yellow-600 hover:text-yellow-800"
                to="/reset"
              >
                Reset
              </Link>
	    <Link
	          className=" text-yellow-600 hover:text-yellow-800"
	  to="/signup"                                                                      >                                                                                    signup                                                                            </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-1 p-6 h-screen bg-gradient-to-br from-yellow-400 to-pink-600">
        <div className="text-4xl text-white font-bold">Contact Support</div>
      </div>
    </div>
  );
};

export default LoginPage;
