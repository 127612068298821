
import {
  collection,
  doc,
  getDocs,
  limit,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { useState } from "react";
import React from "react";

import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { InfoButton, Button } from "../../../components/button";
import { UserCard } from "./UserCard";
const EditorsPage = () => {
  const [formValues, setFormValues] = useState({
    email: "",
    name: "",
    role: "",
    userExists: false,
    id: "",
    errorTxt: "",
    successTxt: "",
  });

  const { successTxt, errorTxt, id, email, name, role, userExists } =
    formValues;

  const handleFormChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const firestore = useFirestore();
  const adminAndEditorsQuery = query(
    collection(firestore, "users"),
    where("role", "!=", "listener")
  );

  // ReactFire!
  const { status, data: adminAndEditorsData } = useFirestoreCollectionData(
    adminAndEditorsQuery,
    {
      idField: "id", // this field will be added to the object created from each document
    }
  );

  const searchUser = async () => {
    try {
      if (email.length > 0) {
        const q = query(
          collection(firestore, "users"),
          where("email", "==", email),
          limit(1)
        );

        const result = await getDocs(q);

        if (result.size > 0) {
          setFormValues({
            ...formValues,
            id: result.docs[0].id,
            name: result.docs[0].data().name,
            role: result.docs[0].data().role,
            userExists: true,
            errorTxt: "",
          });
        } else {
          setFormValues({
            ...formValues,
            id: "",
            name: "",
            role: "",
            userExists: false,
            errorTxt: "No user found!",
          });
        }
      } else {
        setFormValues({
          ...formValues,
          id: "",
          name: "",
          role: "",
          userExists: false,
          errorTxt: "Provide email!",
        });
      }
    } catch (error) {
      setFormValues({
        ...formValues,
        id: "",
        name: "",
        role: "",
        userExists: false,
        errorTxt: error,
      });
    }
  };

  const btnUpdateUser = async () => {
    try {
      if (id !== "" && role !== "") {
        await updateDoc(doc(firestore, "users", id), {
          role: formValues.role,
        });
        setFormValues({
          ...formValues,
          successTxt: "Details Modified.",
        });
      } else {
        setFormValues({
          ...formValues,
          errorTxt: "something went wrong!",
          successTxt: "",
        });
      }
    } catch (error) {
      setFormValues({
        ...formValues,
        errorTxt: error,
        successTxt: "",
      });
    }
  };

  return (
    <div className="container">
      <div>Editors</div>

      <div className="flex flex-col sm:flex-row mt-4">
        <div className="flex-1 p-4">
          <div className="mt-4">
            <div className="mb-1">Email of registered user</div>
            <input
              placeholder="Write e-mail here..."
              type="email"
              className="py-2 px-3 border-0 rounded bg-gray-100 focus:outline-none w-72 invalid:bg-red-100"
              name="email"
              value={email}
              onChange={handleFormChange}
            />
          </div>

          <div className="mt-3">
            <InfoButton child="Search User" onClick={searchUser} />
          </div>

          {errorTxt !== "" ? (
            <div className="mt-3 text-red-500">{errorTxt}</div>
          ) : (
            <></>
          )}

          {userExists ? (
            <div>
              <div className="mt-4">
                <div className="mb-1">Name</div>
                <input
                  placeholder="Name of user..."
                  type="text"
                  className="py-2 px-3 border-0 rounded bg-gray-100 focus:outline-none w-72 invalid:bg-red-100"
                  name="name"
                  value={name}
                  onChange={handleFormChange}
                />
              </div>

              <div className="mt-2">
                <div className="mb-1">User Role</div>
                <select
                  className="py-2 px-3 border-0 rounded bg-gray-100 focus:outline-none w-72"
                  name="role"
                  value={role}
                  onChange={handleFormChange}
                >
                  <option value="admin">Admin</option>
                  <option value="editor">Editor</option>
                  <option value="listener">Listener</option>
                </select>
              </div>
              <div className="mt-2">
                <Button child="Update" onClick={btnUpdateUser} />
              </div>
              {successTxt !== "" ? (
                <div className="mt-2 text-green-500">{successTxt}</div>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className="flex-1 p-4">
          <div className="text-lg">Current Admin &amp; Editors</div>

          <div className="mt-2">
            {status === "loading" ? <span>loading...</span> : <></>}
            {status === "success" ? (
              <div>
                {adminAndEditorsData.map((user) => (
                  <UserCard
                    key={user.id}
                    user={user}
                    editAction={() => {
                      setFormValues({
                        ...formValues,
                        errorTxt: "",
                        successTxt: "",
                        name: user.name,
                        id: user.id,
                        role: user.role,
                        userExists: true,
                        email: user.email,
                      });
                    }}
                  />
                ))}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditorsPage;
